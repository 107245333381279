<template>
  <div class="p-4">
    <a-table
        toolbar-column-filter
        :columns="columns"
        :source="delayed.data"
        :pagination="delayed"
        :loading="loadings.delayed"
        :skeleton-count="10"
        @paginate="fetch({ page: $event })"
        overflow>
      <template #td(semester)="{ item }">
        {{ item.section.semester.number | roman }}
      </template>

      <template #toolbar(search)>
        <h3 class="text-2xl px-2">Retrasos</h3>
      </template>

      <template #toolbar>
        <v-select
            class="w-48"
            :loading="loadings.periods"
            :options="periods"
            v-model="period"
            placeholder="Período"
            :reduce="$0 => $0.value"
            @input="fetch" />
      </template>
    </a-table>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data: () => ({
    delayed: {},
    loadings: {
      delayed: false,
      periods: false
    },
    query: {
      limit: 15,
      page: 1,
      with: 'course,lesson.teacher,section.semester',
      by_delay: 1
    },
    periods: [],
    period: null
  }),
  computed: {
    ...mapState({
      user: ({ session }) => session.user,
    }),
    columns() {
      let columns = [
        { title: 'Fecha', key: 'lesson.date' },
        { title: 'Tema', key: 'lesson.subject' },
        { title: 'Materia', key: 'course.title' },
        { title: 'Semestre', slot: 'semester', key: 'semester' },
        { title: 'Sección', key: 'section.letter_code' },
      ]

      if (this.user.isAdmin) {
        columns.push({ title: 'Profesor', key: 'lesson.teacher', mutate: ({ key }) => `${key.first_name} ${key.last_name}`})
      }

      return columns
    }
  },
  methods: {
    fetch(query) {
      this.loadings.delayed = !this.loadings.delayed
      // const query = { limit: 0, with: 'course,lesson.teacher,section.semester', by_delay: 1, period }
      this.query.page = query.page ? query.page : 1
      const endpoint = this.user.isAdminGroup || this.user.isCoordinator
          ? this.$repository.students.absences(this.$route.params.student, { ...this.query, period: this.period})
          : this.$repository.courseSections.studentAbsences({ ...this.$route.params }, { ...this.query, period: this.period })

      endpoint.then(({ data }) => {
        this.delayed = data
      }).finally(() => this.loadings.delayed = !this.loadings.delayed)
    }
  },
  mounted() {
    this.loadings.periods = !this.loadings.periods
    this.$repository.periods.index({
      limit: 0,
      order_by: 'id',
    })
        .then(({ data }) => {
          this.periods = data.map($0 => ({ label: `${$0.year}-${$0.number}`, value: $0.id }))
        })
        .finally(() => this.loadings.periods = !this.loadings.periods)
  }
}
</script>
